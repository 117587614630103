// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#app-admin-invoicesList {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 100%;
    padding: 2% 3% 2% 3%;
}

#titleTable-invoces {
    display: flex;
    justify-content: center;
    font-size: 1.5rem;
    font-weight: 700;
    color: white;
    background-color: #1578FC;
    padding: 5px;
    border-top-left-radius: 7px;
    border-top-right-radius: 7px;
}

#table-invoices {
    min-width: 90%;
    outline: black;
}

@media (max-width: 800px) {
    #table-invoices {
        padding: 0;
    }
}

#invoices-filters-controls {
    width: 90%;
    display: flex;
    flex-direction: row;
    align-items: flex-start; /* Alinea los elementos a la izquierda */
    gap: 16px; /* Espaciado entre elementos */
    padding: 2% 0% 2% 0%;
}

.loading-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 200px; /* Ajusta la altura según sea necesario */
  }

  .custom-switch .MuiSwitch-switchBase.Mui-checked {
    color: #FC4A1C; /* Cambia el color del círculo del switch */
  }
  
  .custom-switch .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track {
    background-color: #FC4A1C; /* Cambia el color de la pista del switch cuando está activo */
  }
  
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,eAAe;IACf,oBAAoB;AACxB;;AAEA;IACI,aAAa;IACb,uBAAuB;IACvB,iBAAiB;IACjB,gBAAgB;IAChB,YAAY;IACZ,yBAAyB;IACzB,YAAY;IACZ,2BAA2B;IAC3B,4BAA4B;AAChC;;AAEA;IACI,cAAc;IACd,cAAc;AAClB;;AAEA;IACI;QACI,UAAU;IACd;AACJ;;AAEA;IACI,UAAU;IACV,aAAa;IACb,mBAAmB;IACnB,uBAAuB,EAAE,wCAAwC;IACjE,SAAS,EAAE,8BAA8B;IACzC,oBAAoB;AACxB;;AAEA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,aAAa,EAAE,yCAAyC;EAC1D;;EAEA;IACE,cAAc,EAAE,2CAA2C;EAC7D;;EAEA;IACE,yBAAyB,EAAE,8DAA8D;EAC3F","sourcesContent":["#app-admin-invoicesList {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    max-width: 100%;\n    padding: 2% 3% 2% 3%;\n}\n\n#titleTable-invoces {\n    display: flex;\n    justify-content: center;\n    font-size: 1.5rem;\n    font-weight: 700;\n    color: white;\n    background-color: #1578FC;\n    padding: 5px;\n    border-top-left-radius: 7px;\n    border-top-right-radius: 7px;\n}\n\n#table-invoices {\n    min-width: 90%;\n    outline: black;\n}\n\n@media (max-width: 800px) {\n    #table-invoices {\n        padding: 0;\n    }\n}\n\n#invoices-filters-controls {\n    width: 90%;\n    display: flex;\n    flex-direction: row;\n    align-items: flex-start; /* Alinea los elementos a la izquierda */\n    gap: 16px; /* Espaciado entre elementos */\n    padding: 2% 0% 2% 0%;\n}\n\n.loading-container {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    height: 200px; /* Ajusta la altura según sea necesario */\n  }\n\n  .custom-switch .MuiSwitch-switchBase.Mui-checked {\n    color: #FC4A1C; /* Cambia el color del círculo del switch */\n  }\n  \n  .custom-switch .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track {\n    background-color: #FC4A1C; /* Cambia el color de la pista del switch cuando está activo */\n  }\n  \n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modalBox {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  height: 80%;
  background-color: white;
  padding: 16px;
  box-shadow: 24px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
}

.modalBackdrop {
  background-color: rgba(255, 255, 255, 0.6); 
  backdrop-filter: blur(10px); 
}

.pdfViewer {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 16px 0;
}

.pdfFrame {
  width: 100%;
  height: 100%;
  border: none;
}

.modalActions {
  display: flex;
  justify-content: flex-end;
  gap: 8px;
}

.downloadButton {
  margin-right: 8px;
}

.closeButton {
  margin-left: 8px;
}
`, "",{"version":3,"sources":["webpack://./src/components/InvoiceModal/InvoiceModal.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,QAAQ;EACR,SAAS;EACT,gCAAgC;EAChC,UAAU;EACV,WAAW;EACX,uBAAuB;EACvB,aAAa;EACb,gBAAgB;EAChB,kBAAkB;EAClB,aAAa;EACb,sBAAsB;AACxB;;AAEA;EACE,0CAA0C;EAC1C,2BAA2B;AAC7B;;AAEA;EACE,OAAO;EACP,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,cAAc;AAChB;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,YAAY;AACd;;AAEA;EACE,aAAa;EACb,yBAAyB;EACzB,QAAQ;AACV;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,gBAAgB;AAClB","sourcesContent":[".modalBox {\n  position: absolute;\n  top: 50%;\n  left: 50%;\n  transform: translate(-50%, -50%);\n  width: 80%;\n  height: 80%;\n  background-color: white;\n  padding: 16px;\n  box-shadow: 24px;\n  border-radius: 8px;\n  display: flex;\n  flex-direction: column;\n}\n\n.modalBackdrop {\n  background-color: rgba(255, 255, 255, 0.6); \n  backdrop-filter: blur(10px); \n}\n\n.pdfViewer {\n  flex: 1;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  margin: 16px 0;\n}\n\n.pdfFrame {\n  width: 100%;\n  height: 100%;\n  border: none;\n}\n\n.modalActions {\n  display: flex;\n  justify-content: flex-end;\n  gap: 8px;\n}\n\n.downloadButton {\n  margin-right: 8px;\n}\n\n.closeButton {\n  margin-left: 8px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
